var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.session.slot)?_c('div',{staticClass:"detailSesi"},[_c('a-modal',{attrs:{"title":"Update Assignment","visible":_vm.modalVisible,"confirm-loading":_vm.confirmLoading,"maskStyle":{
      opacity: 0.5,
      backgroundColor: 'black',
    },"okText":"Update","okButtonProps":{
      props: {
        shape: 'round',
      },
    },"cancelButtonProps":{
      props: {
        shape: 'round',
      },
    },"id":"modal-update-confirmation"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('p',[_vm._v("Are you sure to update your assignment?")])]),_c('div',{attrs:{"id":"coverDetail"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.kelas)+" | "+_vm._s(_vm.mapel)+" ")]),_c('div',[_vm._v(" Session "+_vm._s(_vm.week)+" / 24 | "+_vm._s(_vm.session.topik && _vm.session.topik.nama ? _vm.topik(_vm.session.topik.nama) : "-")+" | "+_vm._s(_vm.sessionDate)+", "+_vm._s(_vm.sessionTime)+" ")])]),(_vm.isInSubjectHours && _vm.liveClass.zoom_setting)?_c('div',{staticClass:"countdownTImer"},[_c('h4',[_vm._v("SESSION TIME LEFT")]),_c('h2',[_vm._v(_vm._s(_vm.formattedDuration))])]):_vm._e(),_c('div',{attrs:{"id":"contentDetail"}},[_c('div',{staticClass:"d-flex flex-column flex-lg-row align-items-center"},[_c('div',{staticClass:"w-100 w-lg-75"},[_c('a-menu',{staticClass:"menuSchedule",attrs:{"mode":"horizontal"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[_c('a-menu-item',{key:"live class",staticClass:"subMenuDetail"},[(_vm.isLive && _vm.isNow)?_c('a-icon',{staticStyle:{"color":"#ff0000","font-size":"22px"},attrs:{"type":"video-camera"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isLive && _vm.isNow ? "LIVE CLASS" : "NOTES & DETAILS")+" ")],1),_c('a-menu-item',{key:"materials",staticClass:"subMenuDetail"},[_vm._v("MATERIALS")]),_c('a-menu-item',{key:"assignments",staticClass:"subMenuDetail",attrs:{"id":"assignments-student"}},[_vm._v("ASSIGNMENTS")])],1)],1),(
          _vm.current[0] === 'assignments' &&
          _vm.session.tipe_assignment &&
          _vm.session.tipe_assignment !== 'File Upload' &&
          !_vm.assignmentStudent.tugas_nilai
        )?_c('div',{staticClass:"w-auto d-flex flex-column flex-md-row mt-2 ml-3"},[_c('a-button',{staticClass:"mb-3 mb-lg-0 mr-0 mr-md-3",attrs:{"id":"do-assignment","shape":"round","type":_vm.doAssignment ? 'danger' : 'primary',"icon":_vm.doAssignment ? 'close' : 'edit',"disabled":_vm.attendanceIsNull ? true : false},on:{"click":_vm.doAssignmentClick}},[_vm._v(_vm._s(_vm.doAssignment ? "CANCEL" : "DO ASSIGNMENT"))]),(_vm.doAssignment)?_c('a-button',{staticStyle:{"background-color":"#41b883","color":"white"},attrs:{"id":"submit-assignment","shape":"round","icon":"save"},on:{"click":function($event){$event.preventDefault();return _vm.saveDoAssignment.apply(null, arguments)}}},[_vm._v("SUBMIT")]):_vm._e()],1):_vm._e()]),_c('div',[(_vm.liveClass)?_c('LiveClass',{directives:[{name:"show",rawName:"v-show",value:(_vm.current[0] === 'live class'),expression:"current[0] === 'live class'"}],attrs:{"liveClass":_vm.liveClass,"assignmentStudent":_vm.assignmentStudent,"isStudent":true,"isNow":_vm.isNow,"session":_vm.session,"model":_vm.model},on:{"addUploadedImages":_vm.addUploadedImages}}):_vm._e(),_c('Materials',{directives:[{name:"show",rawName:"v-show",value:(_vm.materials && _vm.current[0] === 'materials'),expression:"materials && current[0] === 'materials'"}],attrs:{"videoUrls":_vm.videoUrls,"materials":_vm.materials,"fileMaterials":_vm.fileMaterials}}),_c('Assignments',{directives:[{name:"show",rawName:"v-show",value:(_vm.current[0] === 'assignments'),expression:"current[0] === 'assignments'"}],key:"3",attrs:{"session":_vm.session,"taskType":_vm.session.tipe_assignment,"doAssignment":_vm.doAssignment,"assignmentStudent":_vm.assignmentStudent,"attendanceIsNull":_vm.attendanceIsNull},on:{"changeAttendanceIsNull":_vm.changeAttendanceIsNull,"changedEssayAnswer":_vm.changedEssayAnswer,"changedMultipleChoiceAnswer":_vm.changedMultipleChoiceAnswer}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }